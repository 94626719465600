import React from 'react';

import config from '../../config';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import jumping from '../assets/images/jumping.jpg';
import swing from '../assets/images/swing.jpg';
import onTheBank from '../assets/images/on-the-bank.jpg';

export const allYoutubeVideo = graphql`
  query {
    allYoutubeVideo(limit: 18) {
      edges {
        node {
          thumbnail {
            height
            url
            width
          }
          description
          id
          publishedAt
          title
          videoId
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const vidoes = data.allYoutubeVideo.edges.map(e => e.node);
  console.debug('IndexPage', vidoes);
  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2>{config.heading}</h2>
          <p>{config.subHeading}</p>
          <ul className="actions special">
            <li>
              <Scroll type="id" element="one">
                <a href="/#" className="button primary">
                  Explore
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            Learn More
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2>About Us</h2>
            <p>
              The Dudbots are the 3 beloved children of Albie &amp; Jessica
              Dudley. They are ordinary kids who serve an extraordinary God who
              empowers them to boldly advance His Kingdom.
            </p>
          </header>
          {/* <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul> */}
        </div>
      </section>

      <section id="two" className="wrapper alt style2">
        <section className="spotlight">
          <div className="image">
            <img src={jumping} alt="" />
          </div>
          <div className="content">
            <h2>
              Looking for music <br /> for your worship service?
            </h2>
            <p>
              We would love to partner in ministry with you.{' '}
              <a href="https://forms.gle/mGzh4Jaaeohso19v6" target="_blank">
                Contact us
              </a>{' '}
              for more details!
            </p>
            <p>
              <a
                href="https://forms.gle/mGzh4Jaaeohso19v6"
                target="_blank"
                class="button primary small fit"
              >
                Booking Request
              </a>
            </p>
          </div>
        </section>

        <section className="spotlight">
          <div className="image">
            <img src={onTheBank} alt="" />
          </div>
          <div className="content">
            <h2>WE GOT MERCH!!!</h2>
            <p>
              Looking for a way to support our ministry? Head over to our{' '}
              <a href="https://teespring.com/stores/dudbots" target="_blank">
                new merch shop
              </a>
              , we've got everything from tees to leggings to mugs to pet gear;
              all in your size and your fave color!
            </p>
            <p>
              <a
                href="https://teespring.com/stores/dudbots"
                target="_blank"
                class="button primary small fit"
              >
                Dudbots Store
              </a>
            </p>
          </div>
        </section>

        {/* <section className="spotlight">
          <div className="image">
            <img src={swing} alt="" />
          </div>
          <div className="content">
            <h2>
              Tortor dolore feugiat
              <br />
              elementum magna
            </h2>
            <p>
              Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
              imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
            </p>
          </div>
        </section> */}
      </section>

      <section className="wrapper style5">
        <div className="inner">
          <section>
            <h2>Latest Videos</h2>

            <div className="box alt">
              <div className="row gtr-50 gtr-uniform">
                {vidoes.map(v => (
                  <div className="col-12-xsmall col-6-small col-6-medium col-4-large col-4-xlarge">
                    <span className="image fit">
                      <iframe
                        styleD={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%',
                          border: '0',
                        }}
                        src={`https://www.youtube.com/embed/${v.videoId}`}
                        title={v.description}
                        name={v.title}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        width="100%"
                        height="200"
                        allowFullScreen
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </section>

      <section id="cta" className="wrapper style3">
        <div className="inner">
          <header>
            <h2>We Want To Hear From You</h2>
            <p>
              <span>Reach out to us on </span>
              <a href="https://facebook.com/dudbots" target="_blank">
                Facebook
              </a>
              <span>, </span>
              <a
                href="https://www.youtube.com/channel/UCpRI0xklb6iuYhX-cApXjGA"
                target="_blank"
              >
                YouTube
              </a>
              <span>, </span>
              <a href="https://instagram.com/dudbots" target="_blank">
                Instagram
              </a>
              <span>,&nbsp;or&nbsp;via </span>
              <a href="https://forms.gle/mGzh4Jaaeohso19v6">Email</a>.
            </p>
          </header>
          <ul className="actions stacked">
            <li>
              <a
                href="https://forms.gle/mGzh4Jaaeohso19v6"
                className="button fit primary"
              >
                Email
              </a>
            </li>
            <li>
              <a
                href="https://facebook.com/dudbots"
                className="button fit"
                target="_blank"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
